<template>
    <Popup :width="width" :height="height" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
      <div v-if="editProfilePopup" class="profile-popup" slot="component">
        <p class="profile-popup-title">{{ popupType === "Update" ? updateProfile : createNewProfile }}</p>
        <form @submit.prevent="formSubmit">
          <div class="profile-popup-image">
            <div class="profile-popup-image-container">
              <img v-if="isProfilePicture" :src="profilePicture" class="subscriberimage" alt />
              <img v-else :src="defaultImage" class="subscriberimage" alt />
            </div>
            <div v-if="imageLoader" class="image-loader">
              <Loading></Loading>
            </div>
            <div
              class="icon-container"
              style="position: absolute;top: 0rem;left: 6rem;"
              @click="inputFileChangeMakeEmpty"
              >
              <profiledeleteicon></profiledeleteicon>
              </div>
            <div class="camera-img" v-if="!imageLoader">
             
              <input type="file" id="selectfile"  :value="selectedFile" accept="image/gif, image/jpeg, image/png"
                @change="inputFileChange($event)" />
              <img src="@/assets/icons/camera.svg" :class="[localDisplayLang === 'ara' ? secPlace : ogPlace]" alt />
            </div>
          </div>
          <div class="form-group">
            <div class="error-container" v-if="formError && formError != 'Device Token Expired'">
              <p class="error">{{ formError }}</p>
            </div>
            <!-- Edit Email -->
          <div class="form-control input-container email-ipnut" v-if = "isEditProfileEnabled"  >
              <label for="email" v-if="popupType === 'Update'">{{ $t("Email ID") }}</label>
             
              <input disabled type="text" dir="ltr"  v-model="userEmail" />
              <span class="change">
              <span  v-if="userEmail"  @click="editEmailOrPhNumber" class="input-span">{{ $t("CHANGE") }}</span>
              <span v-else @click="editEmailOrPhNumber" style="font-size:12px;" class="input-span">{{ $t("update") }}</span>
              </span>
            </div>
               <!-- mobile -->
            <div class="form-control"   v-if = "isEditProfileEnabled">
              <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
                <label class="login-label">{{$t("Mobile Number")}}</label>
              </div>
              <div id="input-container" class="lookup-form-container-input-container" dir="ltr">
                <div id="countrycode" class="lookup-form-container-input-container-select-code">
                  <input type="text" v-model="teleCode" placeholder="+91"
                    :class="[localDisplayLang === 'ara' ? 'rightPad' : '']" disabled />
                  <img id="arrow" src="@/assets/icons/down_arrow.svg" alt />
                </div>
                <input id="userEmail" class="lookup-form-container-input-container-inputtext" type="text" dir="ltr"
                  v-model="userMobile" autocomplete="false" @keydown.space.prevent disabled />
                <span class="change">
                  <span  v-if="userMobile" @click="editPhNumber" class="input-span">{{ $t("Change") }}</span>
                   <span  v-else @click="editPhNumber"  style="font-size:10.5px;" class="input-span">{{ $t("update") }}</span>
                </span>
              </div>
              <div class="lookup-form-container-dropselect" v-if="isDropSelect" dir="ltr">
                <div class="lookup-form-container-dropselect-search" id="countrylist-searchtext">
                  <input type="text" class="lookup-form-container-dropselect-search-text" v-model="searchText"
                    :placeholder="`${searchCountryPlaceholder}`" @input="filterCountry" />
                </div>
                <div class="lookup-form-container-dropselect-container">
                  <div class="lookup-form-container-dropselect-container-item" id="countrylist-item"
                    v-for="(item, index) in countryList" :key="index" @click="selectCode(item.dial_code)">
                    <div class="lookup-form-container-dropselect-container-item-span" @mouseover="hover($event)"
                      @mouseleave="out($event)">
                      <span>{{ item.dial_code }}</span>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Edit UserName -->
            <div class="form-control">
              <label for="Name" v-if="popupType === 'Update'">{{ $t("name") }}</label>
              <label for="Name" v-else>
                {{ $t("name") }}
                <span style="color:red;">*</span>
              </label>
              <input type="text" v-model="userName" :placeholder="`${nameplaceholder}`" />
            </div>
            <!-- Age Setting -->
            <div class="form-control">
              <label for="age" v-if="popupType === 'Update'">
                {{ $t("DOB") }}
                <span style="color:red;"></span>
              </label>
              <label for="age" v-else>
                {{ $t("DOB") }}
                <!-- <span style="color:red;">*</span> -->
              </label>
              <br />
              <input 
          type="text" 
          data-date="dd/mm/yyyy" 
          id="addProfileDatePicker" 
          @change= "getChangeFormat()" 
          onfocus="(this.type='date')"  data-date-format="DD/MM/YYYY" 
          class="profileDate" 
          :placeholder="`${datePlaceholder}`" v-model="dob"
          :max="getTodaysDate()"
          inputmode="numeric"
          />

  
  
            </div>
            <!-- Gender Seletion -->
            <div class="form-control">
            <label for="age">{{ $t("gender") }}</label>
            <div class="radio" :class="localDisplayLang === 'ara' ? 'rtl_radio' : ''">
            <input type="radio" id="Male" name="gender" value="MALE" v-model="gender" />
            <label for="Male" :style="[localDisplayLang === 'ara' ? { 'padding-right': '0rem' } : '']">
             {{ $t("male") }}
             <span></span>
             </label>
            <input type="radio" id="Female" name="gender" value="FEMALE" v-model="gender" />
             <label for="Female">
            {{ $t("female") }}
            <span></span>
            </label>
            </div>
            </div>
            <div class="form-control profile-popup-pin-box">
              <label>{{ $t('set pin') }}</label>
              <div id="pininputs"  class="profile-popup-pin-box-inputs">
                <input v-for="(item, index) in 4" :key="index" class="pin" type="password" v-model="userPin[item - 1]"
               @input="grabPin" maxlength="1" autocomplete="off"  value=""  inputmode="numeric" />
              </div>
            </div>
          </div>
          <button class="button-primary profile-popup-btn"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']">{{ popupType
                === "Update" ? updateButton : createButton
            }}</button>
        </form>
          </div>
      <!-- Update Email section -->
      <div v-if="editEmailPopup" class="profile-popup" slot="component">
        <p class="profile-popup-title">{{$t("Change your Email ID")}}</p>
        <div class="form-group">
          <div class="error-container" v-if="formError">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
        <div v-if="userEmail" class="input-container email-ipnut margin-top-bottom">
          <label>{{$t("Your Existing Mail Id")}}</label>
          <p class="details">{{ userEmail }}</p>
        </div>
  
        <div class="input-container email-ipnut margin-top-bottom">
          <label for="email">
            {{$t("Enter Your New Email ID")}}
            <span style="color:red;">*</span>
          </label>
          <input type="email"  v-model="userEmailUpdate" :placeholder="`${emailPlaceholder}`" />
        </div>
  
        <button type="button" @click="switchToUpdateOTP"
          class="button-primary profile-popup-btn primary-net5-button">{{$t("continue")}}</button>
      </div>
      <!-- Update Mobile No section -->
      <div v-if="editMobilePopup" class="profile-popup" slot="component">
        <p class="profile-popup-title">{{$t("Change Your Number")}}</p>
  
        <div class="form-group">
          <div class="error-container" v-if="formError">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
  
        <div v-if="userMobile"  class="input-container email-ipnut margin-top-bottom">
          <label>{{$t("Your Existing Mobile Number")}}</label>
          <p class="details">{{ userMobile }}</p>
        </div>
  
        <div class="input-container email-ipnut margin-top-bottom">
          <label for="mobile">
            {{$t("Enter Your New Mobile Number")}}
            <span style="color:red;">*</span>
          </label>
          <div class="form-control">
            <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            </div>
            <div id="input-container" class="lookup-form-container-input-container" dir="ltr">
              <div id="countrycode" class="lookup-form-container-input-container-select-code">
                <input type="text" v-model="teleCode" placeholder="+91"
                  :class="[localDisplayLang === 'ara' ? 'rightPad' : '']" disabled />
                <img id="arrow" src="@/assets/icons/down_arrow.svg" alt />
              </div>
              <input id="userEmail" class="lookup-form-container-input-container-inputtext" type="text" dir="ltr"
                v-model="userMobileUpdate" autocomplete="false" @keydown.space.prevent />
            </div>
            <div class="lookup-form-container-dropselect" v-if="isDropSelect" dir="ltr">
              <div class="lookup-form-container-dropselect-search" id="countrylist-searchtext">
                <input type="text" class="lookup-form-container-dropselect-search-text" v-model="searchText"
                  :placeholder="`${searchCountryPlaceholder}`" @input="filterCountry" />
              </div>
              <div class="lookup-form-container-dropselect-container">
                <div class="lookup-form-container-dropselect-container-item" id="countrylist-item"
                  v-for="(item, index) in countryList" :key="index" @click="selectCode(item.dial_code)">
                  <div class="lookup-form-container-dropselect-container-item-span" @mouseover="hover($event)"
                    @mouseleave="out($event)">
                    <span>{{ item.dial_code }}</span>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <button type="button" @click="mobileUpdateotp"
          class="button-primary profile-popup-btn primary-net5-button">{{$t("continue")}}</button>
      </div>
  
      <!-- Update Email/Ph OTP number section -->
      <div v-if="updatePopup" class="profile-change" slot="component">
         <p class="profile-popup-edit center"> {{$t("Please enter OTP sent to")}} </p>
         <p class="profile-popup-edit center ">{{userEmailUpdate}}</p>
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
  
        <div class="form-control profile-popup-pin-box">
          <div id="pininputs"  dir="ltr" class="profile-popup-pin-box-inputs">
            <input v-for="(item, index) in numberOfOTPBlocks" :key="index" class="pin" type="text"
              v-model="userPin[item - 1]" @input="grabPin" maxlength="1" inputmode="numeric" />
          </div>
           <div class="form-control">
            <span class="mobile-form-notreceived">{{ $t("not recieved otp") }}</span>
            <span class="mobile-form-resend" @click="resendOtp" v-if="timeleft <= 0">{{ $t("resend") }} </span>
            <span class="mobile-form-resend" v-else>{{timeleft}} {{$t("Seconds")}}</span>
          </div>
        </div>
        <button type="button" @click="otpConfirmButton"
          class="button-primary profile-popup-btn primary-net5-button">{{$t("continue")}}</button>
      </div>
      <!-- /Ph OTP number section -->
      <div v-if="updateMobilePopup" class="profile-change" slot="component">
        <p class="profile-popup-edit center "> {{$t("Please enter OTP sent to")}}  </p>
        <p class="profile-popup-edit center ">{{userMobileUpdate}}</p>
        <div class="form-group">
        <span>{{otpMsgSuccess}}</span>
        <div class="error-container" v-if="formError">
         <p class="error">{{ formError }}</p>
          </div>
        </div>
  
        <div class="form-control profile-popup-pin-box">
          <div id="pininputs"  dir="ltr" class="profile-popup-pin-box-inputs">
            <input v-for="(item, index) in numberOfOTPBlocks" :key="index" class="pin" type="text"
              v-model="userPin[item - 1]" @input="grabPin" maxlength="1" inputmode="numeric" />
          </div>
           <div class="form-control">
            <span class="mobile-form-notreceived">{{ $t("not recieved otp") }}</span>
            <span class="mobile-form-resend" @click="resendOtp" v-if="timeleft <= 0">{{ $t("resend") }} </span>
            <span class="mobile-form-resend" v-else>{{timeleft}} {{$t("Seconds")}} </span>
          </div> 
        </div>
        <button type="button" @click="otpConfirmation"
          class="button-primary profile-popup-btn primary-net5-button">{{$t("continue")}}</button>
      </div>
  
      <!-- Update Email/Ph OTP number section -->
      <div v-if="updateOTPSuccess" class="profile-popup" slot="component">
        <div class="center success-img">
          <img src="./../../../assets/icons/approval.svg" alt="Success" />
        </div>
  
        <p class="profile-popup-title center sub-title">{{$t("Successful")}}</p>
        <p  v-if ="emailSuccess"  class="profile-popup-title center">{{$t("We have successfully Updated your Email ID")}}</p>
         <p  v-else-if ="MobileSuccess"  class="profile-popup-title center">{{$t("We have successfully Updated your Phone number")}}</p>
        <button type="button" @click="closePopup"
          class="button-primary profile-popup-btn primary-net5-button">{{$t("DONE")}}</button>
      </div>
    </Popup>
  </template>
  
  <script>
  import { eventBus } from "@/eventBus";
  import { showSnackBar } from "@/utilities";
  import { mapActions, mapMutations, mapGetters } from "vuex";
  import Utility from "@/mixins/Utility.js";
  import googleAnalytics from "@/mixins/googleAnalytics.js";
  import moment from "moment";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import { _providerId } from "@/provider-config.js";
  // import { mapGetters } from "vuex";
  import { store } from "@/store/store";
  export default {
    props: {
      closePopup: {
        type: Function
      },
      popupType: {
        type: String
      },
      detail: {
        type: Object
   }
    },
    data() {
      return {
        namePlaceholder: "",
        createNewProfile: "",
        updateProfile: "",
        createButton: "",
        updateButton: "",
        width: "32%",
        height :"35%",
        margin: "3% auto",
        radiusType: "full",
        subscriberImage: require("@/assets/icons/Generic.png"),
        defaultImage: require("@/assets/icons/Generic.png"),
        userName: null,
        userEmail: null,
        userMobile: null,
        fileName: null,
        age: null,
        dob:null,
        gender: null,
        userPin: [],
        profilePin: null,
        formError: null,
        kidsProfile: true,
        profilePicture: null,
        imageLoader: false,
        isProfilePicture: false,
        ageRange: ["13-17", "18-30", "31-45", "45+"],
        selectedFile: null,
        providerUniqueId: _providerId,
        secPlace: "sec-place",
        ogPlace: "og-place",
        localDisplayLang: null,
        editProfilePopup: true,
        editEmailPopup: false,
        editMobilePopup: false,
        userEmailUpdate: "",
        userMobileUpdate: "",
        updatePopup: false,
        updateMobilePopup: false,
        numberOfOTPBlocks: 6,
        finalOTP: "",
        updateOTPSuccess: false,
        field: "Email",
        teleCode: null,
        countrycode: "Please Select Country Code",
        countryCodes: [],
        digitCount: 0,
        isDropSelect: false,
        otpMsgSuccess: "",
        searchText: "",
        countryList: [],
        userInput: "",
        datePlaceholder:this.$t(""),
        maxDateLimit: null,
        subscriberDetails: null,
        emailSuccess:null,
        MobileSuccess:null,
        isEmail: false, 
        timeleft: 30,
        dateOfBirth: '',
        isEditProfileEnabled:false,
        isDeleteUpdate:false
  
  
      };
    },
    computed: {
      ...mapGetters(["appConfig", "getRtl", "getCountry"])
    },
  
    watch: {
      formError(val) {
        if (val) {
          setTimeout(() => {
            this.formError = null;
          }, 5000);
        }
      },
  
      updatePopup(val) {
        if (val) {
          this.setupPin();
        }
      },
      updateMobilePopup(val) {
        if (val) {
          this.setupPin();
        }
      },
  
      dob(val) {
        if (val) {
          this.isKidsProfile();
        } else {
          if (!this.kidsProfile) {
            this.kidsProfile = true;
          }
        }
      },
  
      kidsProfile(val) {
        if (!val) {
          this.setupPin();
        }
      },
      userInput(val) {
        if (val) {
          this.verifyInput(val);
        } else {
          this.verifyInput("xyz");
          this.searchText = "";
          this.filterCountry();
          this.fetchCurrentCountryCode();
        }
      }
    },
  
    created() {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
      this.getCountryList();
  
      // Checking for change of language in local storage value
      eventBus.$on("check-local-storage", data => {
        this.localDisplayLang = data;
      });
  
      // Adding age ranges to the array.
      if (this.appConfig.featureEnabled.kidsMode) {
        this.ageRange.unshift("<13");
      }
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(
          localStorage.getItem("subscriberDetails")
        ).data;
      }
    },
    mounted() {
      this.namePlaceholder = this.$t("name");
      this.searchCountryPlaceholder = this.$t("Search")
      this.emailPlaceholder = this.$t("");
      this.createNewProfile = this.$t("create new profile");
      this.updateProfile = this.$t("Edit Profile");
      this.createButton = this.$t("create");
      this.updateButton = this.$t("update");
    //  this.dobPlaceHolder = this.$t(moment(moment(this.detail.dob, "YYYY-MM-DD")).format("DD-MM-YYYY"));
      this.datePlaceholder = this.$t("date of birth");
      this.nameplaceholder = this.$t("Please Enter Your Name");
  
      this.setMaxDateForDatePicker();
      if(this.userEmail){
       this.isEmail = true;
       }
     if (this.userMobile){
       this.isMobile=true
       }
      let self = this;
      window.addEventListener("mouseup", function (event) {
        //if (self.field === "Mobile") {
      if (event.target.parentNode.id === "countrycode") {
       self.toggleDropSelect();
       } else if (
       !event.target.parentNode.id ||
        event.target.parentNode.id === "input-container"
        ) {
        if (self.isDropSelect) {
         self.isDropSelect = false;
         self.searchText = "";
         self.filterCountry();
         document.getElementById("arrow").style.transform = "rotate(0deg)";
          }
        }
        //}
      });
      this.setMaxDateForDatePicker();
      this.setupPin();
      setTimeout(() => {
        this.populateProfile(this.detail);
      }, 50);
  
      // Create profile event handler.
      eventBus.$on("createprofile-response", response => {
        let GA = {};
        if (response.status.reason) {
          this.formError = this.$t(response.reason);
        } else {
          // Logging a create profile google analytics event.
          GA.created_profile_id = response.profilePayload.profileid;
          GA.is_kid = response.profilePayload.kidsmode === "YES" ? true : false;
          GA.has_pin = response.profilePayload.profilepin ? true : false;
          this.createProfileEvent(GA);
          this.$emit("refreshList");
          this.closePopup();
        }
      });
      // Edit profile event handler.
      eventBus.$on("editprofile-response", response => {
        if (response.response.reason) {
            this.formError = this.$t(response.response.reason);
            if (this.profilePin) {
            this.userPin = [];
            this.profilePin = null;
            // showSnackBar(this.$t('profile is updated successfully'));
          }
        } else {
          this.$emit("refreshList");
          this.closePopup();
          showSnackBar(this.$t('profile is updated successfully'));

        }
      });
     // Edit subprofile 
        let adminProfileDetails = localStorage.getItem('subscriberDetails') ? JSON.parse(localStorage.getItem('subscriberDetails')).data : {};
        let profileSubUser = localStorage.getItem('subscriberProfileDetails') ? JSON.parse(localStorage.getItem('subscriberProfileDetails')) : {}; 
        if (adminProfileDetails.subscriberid == profileSubUser.profileid) {
        this.isEditProfileEnabled = true;
        }   
    },
    methods: {
      ...mapMutations(["commitsubscriberdetails"]),
      ...mapActions([
        "subscriberDetails",
        "fileUpload",
        "updateSubscriber",
        "subscriberEmailConfirm"
      ]),
      getChangeFormat() {
    const dob = new Date(this.dob);
    const formatter = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const formattedDob = formatter.format(dob);
    document.getElementById('addProfileDatePicker').setAttribute(
      'data-date',
      formattedDob
    );
  },
  
      getTodaysDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
  
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
  
      today = yyyy + "-" + mm + "-" + dd;
      return today;
      },
  
      setMaxDateForDatePicker() {
        const dateNow = new Date();
  
        const yearsAgo = dateNow.getFullYear() - 13;
  
        const currentMonth = dateNow.getMonth() + 1;
  
        this.maxDateLimit = `${yearsAgo}-${currentMonth < 10 ? "0" + currentMonth : currentMonth
          }-${dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate()
          }`;
  
      },
  
      // Setting up the pin input functionality.
      setupPin() {
      setTimeout(() => {
      this.getId = document.getElementById("pininputs");
      if (this.getId) {
       this.pinFunctionality(this.getId);
        }
        }, 1000);
      },
  //start Timer
   startTimer() {
           this.timeleft = 30;
            var downloadTimer = setInterval(() => {  
            this.timeleft = this.timeleft - 1;
            // document.getElementById("countdowntimer").textContent = timeleft;
            if(this.timeleft == 0)
                clearInterval(downloadTimer);
            },1000);
        } ,
       resendOtp() {
       this.startTimer();
       const payload = {
          email: this.userEmailUpdate, 
          }
          this.updateSubscriber(payload)
          this.formError = this.$t("New OTP Sent To Mail");
          return;
      },
       phoneOtp(){
        this.startTimer();
        const payload = {
          mobileno:this.teleCode + this.userMobileUpdate,
        }
       this.updateSubscriber(payload).then(response => {
          if (response && response.data && response.data.success) {
          this.loader = false;
           } else if (response && response.data && response.data.errorcode === 6220) {
             this.formError = this.$t("New OTP Sent To Mobile");
            } else if (response && response.data && response.data.errorcode) {
             this.formError = this.$t(response.data.reason);
             this.loader = false;
            }
          })
          .catch(err => {
            this.loader = false;
          });
         },
  
      editEmailOrPhNumber() {
        this.editProfilePopup = false;
        this.editEmailPopup = true;
      },
      editPhNumber() {
        this.editProfilePopup = false;
        this.editMobilePopup = true;
      },
  
      switchToUpdateOTP() {
        this.loader = true;
        const isValidEmail = this.validateEmail(this.userEmailUpdate);
        if (!isValidEmail) {
          this.loader = false;
          return;
        }
       this.startTimer()
        const payload = {
        email: this.userEmailUpdate,
        otp: this.userOTP
        };
        // Update Subscription API
        this.updateSubscriber(payload)
        .then(response => {
            if (response && response.data && response.data.success) {
            this.editProfilePopup = false;
            this.editEmailPopup = false;
            this.updateOTPSuccess = true;
            this.loader = false;
            } else if (response && response.data && response.data.errorcode === 6220) {
              // Hide the Templates
            this.editProfilePopup = false;
            this.editEmailPopup = false;
              // Show the OTP pin blocks
            this.updatePopup = true;
            this.loader = false;
            this.otpMsgSuccess  = response.data.reason
              
              
            } else if (response && response.data && response.data.errorcode) {
              this.formError = this.$t(response.data.reason);
              this.loader = false;
            }
          })
          .catch(err => {
            this.loader = false;
            console.error("Update Email Error -> ", err);
          });
      },
      mobileUpdateotp() {
        this.loader = true;
        const isValidNumber = this.validateMobileno(this.userMobileUpdate);
        if (!isValidNumber) {
        this.loader = false;
          return;
        }
       this.startTimer();
        const payload = {
          mobileno: this.teleCode + this.userMobileUpdate,
          otp: this.finalOTP
        };
        // Update Subscription API
        this.updateSubscriber(payload)
       .then(response => {
            if (response && response.data && response.data.errorcode === 6220) {
              // Hide the Templates
              this.editProfilePopup = false;
              // this.editEmailPopup = false;
              this.editMobilePopup = false;
              // Show the OTP pin blocks
              this.updateMobilePopup = true;
  
              this.loader = false;
            } else if (response && response.data && response.data.errorcode) {
              this.formError = this.$t(response.data.reason);
              this.loader = false;
            }
          })
          .catch(err => {
            this.loader = false;
           
          });
      },
  
      otpConfirmButton() {
        if (this.finalOTP.length < this.numberOfOTPBlocks) {
          this.formError = this.$t("Please Enter the complete OTP");
          return;
        }
  
        this.switchToUpdateSuccess();
      },
  
      switchToUpdateSuccess() {
              const payload = {
                  email: this.userEmailUpdate,
                  otp: this.finalOTP,
              };
  
              // Update Subscription API
              this.subscriberEmailConfirm(payload)
              .then((response) => {
        console.log(response);
              if (response.data.success) {			
              this.editProfilePopup = false;
              this.updatePopup = false;
        this.emailSuccess= true;
        this.updateOTPSuccess = true;
        eventBus.$emit("env-new-email-update", this.userEmailUpdate);
        this.subscriberDetails.email = this.userEmailUpdate;
              eventBus.$emit("subscriberDetails");
        console.log("this.subscriberDetails",);
        localStorage.setItem("subscriberDetails", JSON.stringify(this.subscriberDetails));
              } else {
               this.formError = this.$t(response.data.reason);
                      }
                  })
                  .catch((err) => {
                      this.loader = false;
                      this.formError = $t("err.reason");
          
                  });
            },
      otpConfirmation(){
        if (this.finalOTP.length < this.numberOfOTPBlocks) {
          this.formError = this.$t("Please Enter the complete OTP");
          return;
        }
        this.switchGoUpdateSuccess();
      },
      switchGoUpdateSuccess() {
              const payload = {
                  mobileno:this.teleCode + this.userMobileUpdate,
            otp: this.finalOTP,
              };
  
              // Update Subscription API
              this.subscriberEmailConfirm(payload)
              .then((response) => {
      
            if (response.data.success) {
                          // Hide the Templates
                  this.editMobilePopup = false;
          this.editProfilePopup = false;
                  this.updatePopup = false;
          this.updateOTPSuccess = true;
          this.MobileSuccess = true;
          this.updateMobilePopup=false;
          eventBus.$emit("env-new-email-update", this.userMobileUpdate);
          this.subscriberDetails.mobileno = this.userMobileUpdate;
           eventBus.$emit("subscriberDetails");
          localStorage.setItem("subscriberDetails", JSON.stringify(this.subscriberDetails));
                  } else {
                  this.formError = $t(response.data.reason);
                      }
                  })
                  .catch((err) => {
                      this.loader = false;
            this.formError = err.reason;
                      console.error("Update Email Error -> ", err);
                  });
          },
      // Fetch the pins as the user user enter in the input.
      grabPin() {
        let pin = "";
        if (this.userPin.length <= this.numberOfOTPBlocks) {
        this.userPin.forEach(element => {
            pin += element;
          });
         console.log("THE FINAL PIN -> ", pin);
        this.profilePin = pin;
        }
        this.finalOTP = pin;
      },
  
      // To populate the profile details for edit profile scenario.
     
      populateProfile(detail) {
        if (Object.keys(detail).length > 0) {
         if (detail.picture) {
         this.profilePicture = detail.picture;
         this.isProfilePicture = true;
          }
          this.userName = detail.profilename && detail.profilename;
          this.userEmail = this.subscriberDetails.email && this.subscriberDetails.email;
          this.userMobile = this.subscriberDetails.mobileno && this.subscriberDetails.mobileno.slice(3);
          // this.dob = moment(detail.dob, "DD-MM-YYYY");
          // this.dob = moment(moment(detail.dob, "YYYY-MM-DD")).format(
          //   "DD-MM-YYYY"
          // );
          this.dateOfBirth = moment(moment(detail.dob, "YYYY-MM-DD")).format(
            "DD-MM-YYYY"
          );
          this.gender = detail.gender ? detail.gender : "";
          let profileAge = parseInt(detail.age);
          this.getSubscriberAgeRange(profileAge);
          console.log("profile popup Email", this.userEmail);
          console.log("profile popup Mobile", this.userMobile);
          // this.dob = detail && detail.dob ? moment(detail.dob, "DD-MM-YYYY") : "";

          // if (detail.dob) {
          //   this.dob = moment(moment(detail.dob, "YYYY-MM-DD")).format(
          //   "DD-MM-YYYY"
          // );
          // } else {
          //   const d = new Date();
          //   d.setFullYear(2004);
          //   this.dob = moment(moment(d, "YYYY-MM-DD")).format(
          //   "DD-MM-YYYY"
          // );
          // }
         
          // this.gender = detail.gender ? detail.gender : "";
          // let profileAge = parseInt(detail.age);
          // this.getSubscriberAgeRange(profileAge);
        }
      },
      inputFileChangeMakeEmpty () {
        this.profilePicture = require("@/assets/icons/Generic.png")
        this.fileName = ""
        this.isDeleteUpdate=true;
      },
      // Function which uploads the profile picture.
      inputFileChange(event) {
        let uploadedFile = event.target.files[0];
        if (!uploadedFile) return;
        let imageSize = Math.round(uploadedFile.size / 1000);
        // if (imageSize < 10 || imageSize > 1000) {
        // this.formError = "Image size should be betwwn 10 kb to 1 MB";
        // return;
        // }
        var _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(uploadedFile);
  
        let fileObj = {
          filename: uploadedFile
        };
  
        this.imageLoader = true;
        this.fileUpload(fileObj)
       .then(response => {
        console.log("fileupload response", response);
        this.imageLoader = false;
        if (response.data.reason) {
         showSnackBar(this.$t(response.data.reason));
         this.formError = $t(response.data.reason);
         } else {
          if (Object.keys(this.detail).length === 0) {
          this.profilePicture = _URL.createObjectURL(uploadedFile);
         } else {
           this.profilePicture = _URL.createObjectURL(uploadedFile);
              }
          this.isProfilePicture = true;
          this.fileName = response.data.success;
            }
          })
         .catch(error => console.log(error));
      },
      // Checking if it is kids mode or not.
      isKidsProfile() {
        let d = new Date();
        let currentYear = d.getFullYear();
        let userYear = moment(moment(this.dob, "YYYY-MM-DD"))
        .format("YYYY-MM-DD")
        .split("-")[0];
        userYear = parseInt(userYear);
        if (currentYear - userYear >= 0) {
        console.log("user age", currentYear - userYear);
        if (currentYear - userYear < 13) {
        this.kidsProfile = true;
          } else {
        this.kidsProfile = false;
          }
        }
      },
  
      // Select age range.
      selectAgeRange(item, index) {
        console.log("index", index);
        this.dob = "";
        let dom = document.getElementsByClassName("age-range-item");
        // Removing styles from all the grid items.
        if (dom && dom.length > 0) {
          for (let i = 0; i < dom.length; i++) {
            dom[i].style.backgroundColor = "transparent";
            dom[i].style.color = "rgba(255,255,255,0.6)";
          }
  
          // Applying styles for particular grid item.
          dom[index].style.backgroundColor = "#000000";
          dom[index].style.color = "#ffffff";
  
          //subtract the current date from selected date range.
          //example: if age range selected is 18 then subtract the current year with 18 years.
          console.log("age range", item);
  
          switch (item) {
            case "<13":
              console.log("age is 10", this.getCurrentDate(10));
              this.dob = this.getCurrentDate(10);
              break;
            case "13-17":
              console.log("age is 13", this.getCurrentDate(13));
              this.dob = this.getCurrentDate(13);
              break;
            case "18-30":
              console.log("age is 18", this.getCurrentDate(18));
              this.dob = this.getCurrentDate(18);
              break;
            case "31-45":
              console.log("age is 31", this.getCurrentDate(31));
              this.dob = this.getCurrentDate(31);
              break;
            case "45+":
              console.log("age is 45", this.getCurrentDate(46));
              this.dob = this.getCurrentDate(46);
              break;
            default:
              break;
          }
        }
      },
  
      // Get profile age range
      getSubscriberAgeRange(data) {
        let item, index;
        switch (true) {
          case data < 18:
            item = "13-17";
            index = 0;
            this.selectAgeRange(item, index);
            // console.log("subscriberAgeRange", this.subscriberAgeRange);
            break;
          case data < 31:
            item = "18-30";
            index = 1;
            this.selectAgeRange(item, index);
            // console.log("subscriberAgeRange", this.subscriberAgeRange);
            break;
          case data < 45:
            item = "31-45";
            index = 2;
            this.selectAgeRange(item, index);
            console.log("subscriberAgeRange", this.subscriberAgeRange);
            break;
          case data > 45:
            item = "45+";
            index = 3;
            this.selectAgeRange(item, index);
            console.log("subscriberAgeRange", this.subscriberAgeRange);
            break;
          default:
            break;
        }
      },
      //dateofbirthvalidate
      dateofbirthvalidate(val) {
        if (val) {
          this.dateOfBirth = val
          this.isKidsProfile();
        } else {
          if (!this.kidsProfile) {
            this.kidsProfile = true;
          }
        }
      },
      // Form submit function.
      formSubmit() {
        if (this.validatePreviousData()) return;
        if (!this.validateName(this.userName) || !this.validateDOB(this.dateOfBirth))
          return;
        let payload = {};
        if (this.userName) {
          if (this.popupType === "Create") {
            payload.profilename = this.userName;
          } else {
            payload.subscribername = this.userName;
          }
        }
  
        if (this.detail && this.detail.profileid) {
          payload.profileid = this.detail.profileid;
        }
  
          if (this.dob ) {
             let dobItem = this.dob.split('-');
             if (dobItem[2] && dobItem[2].length == 4) {
              payload.dob = dobItem[2] + '-' + dobItem[1] + '-' + dobItem[0];
             } else if (dobItem[2] && dobItem[2].length == 2) {
              payload.dob = dobItem[0] + '-' + dobItem[1] + '-' + dobItem[2];
             } else {
              dobItem = this.dob.split('/');

              payload.dob = dobItem[2] + '-' + dobItem[1] + '-' + dobItem[0];

             }
              
        }

  
        if (this.kidsProfile) {
          payload.kidsmode = "YES";
        } else {
          payload.kidsmode = "NO";
        }
  
        if (this.gender) {
          payload.gender = this.gender;
        }
  
        if (this.fileName != null) {
          payload.picture = this.fileName;
        }
  
        if (this.profilePin) {
          payload.profilepin = this.profilePin;
        }
  
        if (this.popupType === "Create") {
          eventBus.$emit("createProfile", payload);
        } else {
          eventBus.$emit("editProfile", payload);
        }
      },
      validatePreviousData() {
    const detailDate = this.detail.dob;
    const formDate = this.dob ? this.dob : this.detail.dob;
    const isDeleteUpdate = this.isDeleteUpdate;
  
    if (
      this.detail.profilename === this.userName &&
      detailDate == formDate &&
      ((!this.detail.gender && !this.gender) ||
        this.detail.gender === this.gender) &&
      !this.profilePin &&
      !this.fileName &&
      !isDeleteUpdate
    ) {
      this.formError = this.$t("Nothing to update");
      return true;
    } else {
      return false;
    }
  }
  ,
      toggleDropSelect() {
        this.isDropSelect = !this.isDropSelect ? true : false;
        if (this.isDropSelect) {
          document.getElementById("arrow").style.transform = "rotate(-180deg)";
        } else {
          document.getElementById("arrow").style.transform = "rotate(0deg)";
        }
      },
      filterCountry() {
        let searchText = this.searchText;
        this.countryList = [];
  
        if (!searchText) {
          this.countryList = this.countryCodes;
        } else {
          let searchText = this.searchText.toLowerCase();
          let arr = [];
  
          arr = this.countryCodes.filter(element => {
            console.log("element", element.name.toLowerCase());
            return element.name.toLowerCase().startsWith(searchText);
          });
  
          this.countryList = arr;
        }
      },
      verifyInput(value) {
        let digits = /^[0-9]+$/;
        let alphaNumeric = /^[0-9a-zA-Z]+$/;
  
        if (digits.test(value)) {
          this.field = "Mobile";
          console.log("entered mobile");
          document.getElementById("userEmail").setAttribute("maxLength", 15);
          document.getElementById("userEmail").style.width = "70%";
          if (document.getElementById("countrycode")) {
            document.getElementById("countrycode").style.display = "block";
            document.getElementById("countrycode").style.width = "30%";
          }
        } else if (alphaNumeric.test(value)) {
          this.field = "Email";
          this.isDropSelect = false;
          console.log("entered email");
          document.getElementById("userEmail").removeAttribute("maxLength");
          document.getElementById("userEmail").style.width = "100%";
          if (document.getElementById("countrycode")) {
            document.getElementById("countrycode").style.display = "none";
            document.getElementById("countrycode").style.width = "0%";
          }
        }
      },
      getCountryList() {
        store
        .dispatch("getCountryList")
        .then(response => {
        this.countryCodes = response;
        this.countryList = this.countryCodes;
            //fetching country code.
            if (this.getCountry) {
              this.fetchCurrentCountryCode();
            }
          })
          .catch(error => console.log(error));
      },
      fetchCurrentCountryCode() {
        let index = this.countryList.findIndex(element => {
          return element.code === this.getCountry.CountryCode;
        });
  
        if (index > -1) {
          this.teleCode = this.countryList[index].dial_code
            ? this.countryList[index].dial_code
            : "";
        }
      },
      countryCodeSelect(code) {
        this.teleCode = code;
        document.getElementById("input-container").style.display = "block";
        document.getElementById("expanded-container").style.display = "none";
      },
      toggleDropSelect() {
        this.isDropSelect = !this.isDropSelect ? true : false;
        if (this.isDropSelect) {
          document.getElementById("arrow").style.transform = "rotate(-180deg)";
        } else {
          document.getElementById("arrow").style.transform = "rotate(0deg)";
        }
      },
      selectCode(code) {
        console.log("selected code", code);
        this.teleCode = "";
        this.teleCode = code;
        this.isDropSelect = false;
  
        document.getElementById("arrow").style.transform = "rotate(0deg)";
      },
      hover(e) {
        if (
          e.target.parentNode.className ===
          "lookup-form-container-dropselect-container-item"
        ) {
          var color = "var(--clr-saina-country-code-select)";
         e.target.parentNode.style.backgroundColor = color;

        }
      },
      out(e) {
        if (
          e.target.parentNode.className ===
          "lookup-form-container-dropselect-container-item"
        ) {
          var color = "var(--clr-saina-country-fetch)";
          e.target.parentNode.style.backgroundColor = color;
        }
      }
    },
    components: {
      DatePicker,
      Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
      Loading: () =>
      import(
          /* webpackChunkName: "loading" */ "@/components/Templates/Loading.vue"
        ),
      profiledeleteicon: () => import(/* webpackChunkName: "deleteicon" */ "../../SvgImages/deleteIconsvg.vue"),
    },
    mixins: [Utility, googleAnalytics]
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  .email-ipnut {
      height: 80px;
      width: 100% !important;
  }
   .profile-change{
      padding: 3.4rem;
      margin-top: 25%;
      text-align: center;
      // white-space: nowrap;  
      color: $clr-light-gd4;
      font-size: 1rem;
      flex-basis: 100%;
      color: #c1c1c1;
      font-family: $font-regular;
      word-break: break-all;
      margin-bottom: 30px;
      // display: inline-block;
    &-edit{
      font-weight: bold;
      letter-spacing: 0;
      margin-bottom: 1.2rem;
      word-break: break-all;
      margin-bottom: 30px;
    //  vertical-align: middle;
  }
  
    }
  .mobile-form {
    .otp_error_text {
      color: #ffffff;
      opacity: 0.5;
      font-family: $font-regular;
      line-height: 14px;
      margin-bottom: 20px;
      font-size: 10px;
      font-style: italic;
    }
    &-notreceived {
      opacity: 0.5;
      color: #777;
      font-family: $font-regular;
      font-size:14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      display: inline-block;
    }
    &-resend {
      color: #777;
      font-family: $font-regular;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      padding: 5px;
      cursor: pointer;
    }
    &-timer {
      color: #777;
      font-family: $font-regular;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
  
  .details {
    color:$clr-saina-light-dark-txt;;
    padding: 0.2rem;
  }
  .og-place
  .margin-top-bottom {
    margin: 2rem 0;
  }
  
  .center {
    text-align: center;
    // margin-bottom: 5px;
    color: $clr-saina-light-dark-txt;
  }
  
  .success-img {
    margin: 2.5rem 0;
  }
  
  .sub-title {
    font-size: 2rem !important;
    font-weight: bold !important;
  }
  .input-container {
    position: relative;
    .change {
      position: absolute;
      top: 50%;
      right: 3%;
      background: $clr-saina-light-dark-bg;
      padding: 0rem 0.3rem;
  
      .input-span {
        // color: orange;
        color: $clr-saina-light-dark-main-bg; // nammaflix
        font-size: 10px;
        font-weight: bold;
        font-family: $font-regular;
        cursor: pointer;
      }
    }
    .rtl-change {
      color: #ffffff;
      position: absolute;
      top: 32%;
      left: 3%;
    }
  
    .rtl-eye {
      position: absolute;
      top: 35%;
      left: 3%;
      opacity: 0.5;
  
      img {
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
    }
  }
  
  .profile-popup {
    padding: 3.4rem;
    margin-top: 25%;
    &-title {
      color: $clr-editprofile-tittle;
      font-size: 1rem;
      font-family: $font-regular;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
      margin-bottom: 1.2rem;
    }
    &-image {
      position: relative;
      margin-bottom: 1.2rem;
      width: 100px;
      height: 100px;
  
      &-container {
        height: 100%;
  
    
        .subscriberimage {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
  
      .image-loader {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .camera-img {
        position: absolute;
        top: 0%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.26);
        opacity: 0;
        cursor: pointer;
        z-index: 100;
  
        &:hover {
          opacity: 1;
        }
  
        // img {
        //   position: relative;
        //   width: 20px;
        //   top: -50%;
        //   left: 39%;
        //   z-index: -1;
    
        .og-place {
          position: relative;
          width: 20px;
          top: -50%;
          left: 39%;
          z-index: -1;
        }
  
        .sec-place {
          position: relative;
          width: 20px;
          top: -50%;
          left: -40%;
          z-index: -1;
        }
  
        input[type="file"] {
          padding: 2.4em;
          opacity: 0;
          z-index: 100;
          border-radius: 5em;
          width: 100%;
  
          &:focus {
            background-color: none;
          }
        }
      }
    }
  
    &-gender {
      -webkit-appearance: none;
      background: url("../../../assets/icons/down_arrow.svg") no-repeat 98%;
    }
  
    &_rtl_gender {
      -webkit-appearance: none;
      background: url("../../../assets/icons/down_arrow.svg") no-repeat 3%;
    }
    &-pin-box {
      padding-bottom: 18px;
  
      label {
        font-size: 0.8rem;
      }
  
      &-inputs {
        display: flex;
        align-items: center;
        margin-top:1rem;
  
        .pin {
          width: 100%;
          margin: 5px;
          text-align: center;
        }
      }
    }
  
    &-btn {
      margin-top: 2rem;
      width: 100%;
    }
    .radio input[type="radio"] {
      display: none;
      /*removes original button*/
    }
  
    .radio label {
      position: relative;
      padding-right: 1.2rem;
      color: $clr-popup-label;
    }
  
    .radio input[type="radio"]+label:before {
      /*styles outer circle*/
      content: " ";
      display: inline-block;
      position: relative;
      top: 3px;
      margin: 0 5px 0 0;
      width: 13px;
      height: 13px;
      border-radius: 11px;
      border: 2px solid $clr-popup-label;
      background-color: transparent;
    }
    .radio input[type="radio"]:checked+label {
      color:$clr-create-main-txt;
      font-weight: bold;
    }
    .radio input[type="radio"]:checked+label:before {
      border: 2px solid $clr-radio-btn-border;
    }
    .radio input[type="radio"]:checked+label>span {
      border-radius: 11px;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      background-color:$clr-radio-checkbox-input-bg;
    }
    .rtl_radio input[type="radio"] {
      display: none;
      /*removes original button*/
    }
    .rtl_radio label {
      position: relative;
      margin-left: 15px;
      padding: 0;
      color: $clr-popup-label;
    }
    .rtl_radio input[type="radio"]+label:before {
      /*styles outer circle*/
      content: " ";
      display: inline-block;
      position: relative;
      top: 3px;
      margin: 0 5px 0 0;
      width: 13px;
      height: 13px;
      border-radius: 11px;
      border: 2px solid $clr-popup-label;
      background-color: transparent;
    }
    .rtl_radio input[type="radio"]:checked+label {
      color: $clr-create-main-txt;
    }
    .rtl_radio input[type="radio"]:checked+label:before {
      border: 2px solid $btn-clr;
    }
    .rtl_radio input[type="radio"]:checked+label>span {
      border-radius: 11px;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 5px;
      right: 10px;
      display: block;
      background-color: $btn-clr;
    }
  }
  .lookup-form-container {
    position: relative;
  
    &-input-container {
      display: flex;
  
      &-select-code {
        position: relative;
        z-index: 1001;
        width: 30%;
        // .rightPad {
        //   padding-right: 35px;
        // }
        cursor: pointer;
  
        img {
          position: absolute;
          top: 43%;
          right: 12%;
        }
      }
    }
  
    &-dropselect {
      position: absolute;
      top: 130%;
      background-color: $clr-saina-country-fetch;
      width: 100%;
      border-radius: 0.2rem;
      z-index: 1;
  
      &-search {
        input[type="text"] {
       border: none;
       margin: 0;
       padding: 0.7rem;
       opacity: 0.5;
       color: $clr-saina-light-dark-txt;
       font-family: $font-regular;
       font-size: 14px;
       font-weight: 500;
       letter-spacing: 0;
       line-height: 17px   ;
        }
      }
      &-container {
        max-height: 200px;
        overflow-y: scroll;
  
        &-item {
          padding: 0.7rem;
          position: relative;
          z-index: 1001;
  
          &-span {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
  
            span {
              color:$clr-create-main-txt;
              font-family: $font-regular;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
              text-transform: uppercase;
              position: relative;
              z-index: -1;
            }
          }
        }
      }
    }
  
    &-expanded-container {
      display: none;
  
      &-expanded-select {
        width: 100%;
        color: $clr-saina-light-dark-txt;
        font-family: $font-regular;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        padding: 0.7rem;
        -webkit-appearance: none;
        // background: url("../../../../assets/icons/down_arrow.svg") no-repeat 97%;
        background-color: $clr-saina-country-fetch;
  
        &-option {
          margin: 0.7rem;
          color: red;
        }
      }
    }
  
    &-mobile {
      padding-left: 25%;
    }
  
    &-continue-button {
      margin-top: 30px;
    }
  }
  
  .change {
    position: absolute;
    top: 50%;
    right: 3%;
  
    .input-span {
      // color: orange;
      color: $clr-saina-light-dark-main-bg; // nammaflix
      font-size: 10px;
      font-weight: bold;
      font-family: $font-regular;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1250px) {
  
  .mobile-form-notreceived{
    font-size: 11px;
  }
  .mobile-form-resend{
  font-size: 11px;
  }
    .profile-popup {
      padding: 3rem 1.5rem;
     &-pin-box {
      padding: 0.5rem 0.2rem;
  
      label {
        font-size: 0.8rem;
      }
  
      &-inputs {
        display: flex;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-column-gap: 3px;
        margin-left: -0.3rem;
        margin-top:0.6rem;
        .pin {
          width: 100%;
          margin: 5px;
          text-align: center;
        }
      }
    }
      &-title {
        font-size: .9rem;
      }
      &-image {
       width: 80px;
       height: 80px;
  
     .camera-img {
       opacity: 1;
  
      img {
      top: -70%;
          }
  
        .og-place {
           top: -70%;
          }
  
          .sec-place {
            top: -70%;
          }
        }
      }
    }
  
  }
  @media only screen and (width:768px) and (resolution: 163dpi) {
   .profile-popup {
      margin-top: 25%;
      max-height: 50vh;
      overflow-y: scroll;
  }
  
  
  }
  @media only screen and (max-width: 768px) {
    .profile-popup {
      margin-top: 25%;
      max-height: 70vh;
      overflow-y: scroll;
  }
  }
  //  input[type="date"]::-webkit-calendar-picker-indicator {
  //     filter: invert(1);
  //     margin: 0px;
  //     margin-right: 20px !important;
  //  }
  .profileDate {
      // height: 2rem;
      // background: rgb(219, 195, 195);

      &::-webkit-calendar-picker-indicator {
        filter: invert(1) sepia(100%) saturate(10000%) hue-rotate(180deg);
      }
    }
  
   .icon-container{
    width: 26px;
    height: 26px;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in;
    cursor: pointer;
    img {
      max-width: 16px !important;
      max-height: 16px !important;
      height: 12px;
      width: 12px;
    }
  }
  
  // .icon-container:hover{
  //   background-color: $primary-btn-color;
  // }
  
  
  // #addProfileDatePicker {
  //     position: relative;
  //     position: relative;
  //     // width: 14rem;
  //     height: 2.5rem;
  //     color: white;
  //     margin: 0.4rem 0;
  //     padding: 0.7rem;
  //     color: white;
  // }
  
  // #addProfileDatePicker:before {
  //     position: absolute;
  //     content: attr(data-date);
  //     display: inline-block;
  // }
  
  //  #addProfileDatePicker::-webkit-datetime-edit, #addProfileDatePicker::-webkit-inner-spin-button, #addProfileDatePicker::-webkit-clear-button {
  //     display: none;
  // }
  
  //  #addProfileDatePicker::-webkit-calendar-picker-indicator {
  //     position: absolute;
  //     right: 2px;
  //     top: 4px;
  //     height: 13rem;
  //     width: 7%;
  //     color: black;
  //     opacity: 1;
  // }
  
  </style>
  